



























































































































































































































.result-bingo {
  &-dividir {
    margin: 10px 0px;
    border: 1px solid #EE7D00
  }
  &-title {
    color:#EE7D00;
    font-size: 18px;
    span {
      font-weight: bold;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  &-title-bingo {
    color:#EE7D00;
    font-size: 16px;
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: center;
  }
  &-number {
    position: relative;
    display: inline-block;
    span {
      position: absolute;
      font-weight: bold;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: #666;
      opacity: 0.5;
    }
    img {
      max-width: 100%;
      opacity: 0.1;
    }
  }
  &-number.winners {
    span {
      color: #EE7D00;
      display: initial !important;
      opacity: 1;
      font-weight: bold;
    }
    img {
      display: initial !important;
      opacity: 1;
    }
  }

  &-table {
    display: inline-block;
    font-weight: bold;
    color: #3d80b4 !important;
    tbody tr td {
      color: #3d80b4 !important;
      &.bola{
        position: relative;
        padding: 0;
        text-align: center;
        span{
          position: absolute;
          font-weight: bold;
          z-index: 1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          color: #3d80b4;
        }
      }
    }
  }
}
